import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  methods: {
    validateInvoice(param) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let data = param.invoice;
          let actionArray = ["send_as_email", "mark_as_paid"];
          if (_this.lodash.isEmpty(data) === false) {
            if (
              data.action &&
              _this.lodash.includes(actionArray, data.action) === false
            ) {
              reject(
                "You have selected invalid action. Please reload page and try again."
              );
            } else if (data.reference && data.reference.length > 100) {
              reject("Reference length must be less than or equal to 100.");
            } else if (data.customer <= 0) {
              reject("Customer is required.");
            } else if (data.property <= 0) {
              reject("Property is required.");
            } else if (data.contact_person <= 0) {
              reject("Contact person is required.");
            } else if (data.title && data.title.length > 255) {
              reject(
                "Invoice Subject length must be less than or equal to 255."
              );
            } else if (data.attention && data.attention.length > 255) {
              reject("Attention length must be less than or equal to 255.");
            } else if (data.admin_remark && data.admin_remark.length > 1024) {
              reject("Admin notes length must be less than or equal to 1024.");
            } else if (data.client_remark && data.client_remark.length > 1024) {
              reject("Client notes length must be less than or equal to 1024.");
            } else if (
              data.term_conditions &&
              data.term_conditions.length > 1024
            ) {
              reject(
                "Terms & conditions length must be less than or equal to 1024."
              );
            } else if (
              data.additional_remarks &&
              data.additional_remarks.length > 1024
            ) {
              reject("Decription length must be less than or equal to 1024.");
            } else if (
              data.discount_value &&
              _this.lodash.isNumber(data.discount_value) === false
            ) {
              reject("Discount value must be a number");
            } else if (
              data.adjustment &&
              _this.lodash.isNumber(data.adjustment) === false
            ) {
              reject("Adjustment value must be a number");
            } else if (_this.lodash.isNumber(data.discount_type) === false) {
              reject("Discount type must be a $ of %");
            } else if (
              _this.lodash.isNumber(data.discount_value_type) === false
            ) {
              reject("Discount value type must be a number");
            } else if (_this.lodash.isNumber(data.notify_admin) === false) {
              reject("Email notification : Admin value must be a number");
            } else if (_this.lodash.isNumber(data.notify_customer) === false) {
              reject("Email notification : Customer value must be a number");
            } else if (_this.lodash.isNumber(data.issued_type) === false) {
              reject("Issued type must be a number");
            } else if (_this.lodash.isNumber(data.payment_due) === false) {
              reject("Payment due value must be a number");
            } else if (
              data.issued_type == 1 &&
              moment(data.invoice_date).isValid() === false
            ) {
              reject("Issued date is required and must be a valid date.");
            } else if (
              data.payment_due == 5 &&
              moment(data.due_date).isValid() === false
            ) {
              reject("Due date is required and must be a valid date.");
            } else if (
              _this.lodash.isEmpty(data.custom_field) === false &&
              _this.lodash.isArray(data.custom_field)
            ) {
              let errors = [];
              let validFieldType = [
                "text",
                "textarea",
                "checkbox",
                "dropdown",
                "date",
                "time",
                "radio"
              ];
              for (let i = data.custom_field.length - 1; i >= 0; i--) {
                if (data.custom_field[i].field_name.mandatory) {
                  if (
                    !data.custom_field[i].field_name.value ||
                    data.custom_field[i].field_name.value > 100
                  ) {
                    errors.push(
                      "Custom field value is required and length must be less than or equal to 100."
                    );
                  }
                }
                if (
                  _this.lodash.includes(
                    validFieldType,
                    data.custom_field[i].field_type
                  ) === false
                ) {
                  errors.push("Custom field type must be a valid type.");
                }
              }
              reject(errors.join(", "));
            } else {
              resolve(data);
            }
          } else {
            reject("Invoice data not found.");
          }
        } catch (error) {
          reject(error.message);
        }
      });
    }
  }
};
